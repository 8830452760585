import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './header.module.css';
import { TitleWithEntityCount } from '../../../components/text';
import {
  activeOrganizationSelector,
  organizationCountSelector,
  listingOrganizationsSelector,
} from '../../../selectors/organizations';

function Header() {
  const [t] = useTranslation('organization', { keyPrefix: 'header' });

  const organization = useSelector(activeOrganizationSelector);

  const listing = useSelector(listingOrganizationsSelector);

  const count = useSelector(organizationCountSelector);

  return (
    <div className={styles.header}>
      <div className={styles.header}>
        <TitleWithEntityCount
          title={t('title')}
          entityText={t('entityCount', { count })}
          loading={count === 0 && listing}
        />
      </div>
      <div className={styles.right}>
        <span>
          <Trans
            t={t}
            i18nKey="id"
            components={{ bold: <b /> }}
            values={{
              name: organization.short_name,
              id: organization.id,
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default Header;
