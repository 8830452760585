import clsx from 'clsx';
import styles from './circle-spinner.module.css';

type CircleSpinnerProps = {
  className?: string;
  color?: string;
};

function CircleSpinner(
  props: CircleSpinnerProps & React.SVGProps<SVGCircleElement>
) {
  const { className, color, ...rest } = props;

  return (
    <svg className={clsx([styles.spinner, className])} viewBox="0 0 50 50">
      <circle
        className={styles.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        stroke={color}
        {...rest}
      />
    </svg>
  );
}

CircleSpinner.defaultProps = {
  className: null,
  color: 'var(--indivd-orange)',
};

export default CircleSpinner;
