import {
  CLEAR_ZONE_SELECTIONS,
  CREATE_ZONE_FAILURE,
  CREATE_ZONE_REQUEST,
  CREATE_ZONE_SUCCESS,
  LIST_ZONES_BY_ORGANIZATION_SUCCESS,
  SELECT_ZONE_ALL,
  SELECT_ZONE_WITHOUT,
  SELECT_ZONE_WITH_CTRL,
  SELECT_ZONE_WITH_SHIFT,
  UPDATE_ZONE_FAILURE,
  UPDATE_ZONE_REQUEST,
  UPDATE_ZONE_SUCCESS,
  DELETE_ZONE_REQUEST,
  DELETE_ZONE_FAILURE,
  DELETE_ZONE_SUCCESS,
  LIST_ZONES_BY_ORGANIZATION_FAILURE,
  LIST_ZONES_BY_ORGANIZATION_REQUEST,
  LIST_ZONES_FAILURE,
  LIST_ZONES_REQUEST,
  LIST_ZONES_SUCCESS,
} from '../constants/action-types';

export const listZonesRequest = () => ({
  type: LIST_ZONES_REQUEST,
});

export const listZonesSuccess = (zones) => ({
  type: LIST_ZONES_SUCCESS,
  zones,
});

export const listZonesFailure = (error) => ({
  type: LIST_ZONES_FAILURE,
  error,
});

export const listZonesByOrganizationRequest = () => ({
  type: LIST_ZONES_BY_ORGANIZATION_REQUEST,
});

export const listZonesByOrganizationSuccess = (zones) => ({
  type: LIST_ZONES_BY_ORGANIZATION_SUCCESS,
  zones,
});

export const listZonesByOrganizationFailure = (error) => ({
  type: LIST_ZONES_BY_ORGANIZATION_FAILURE,
  error,
});

export const updateZoneRequest = (id, zone) => ({
  type: UPDATE_ZONE_REQUEST,
  id,
  zone,
});

export const updateZoneSuccess = (zone) => ({
  type: UPDATE_ZONE_SUCCESS,
  zone,
});

export const updateZoneFailure = (error, validation) => ({
  type: UPDATE_ZONE_FAILURE,
  error,
  validation,
});

export const createZoneRequest = (zone) => ({
  type: CREATE_ZONE_REQUEST,
  zone,
});

export const createZoneSuccess = (zone) => ({
  type: CREATE_ZONE_SUCCESS,
  zone,
});

export const createZoneFailure = (error: unknown, validation) => ({
  type: CREATE_ZONE_FAILURE,
  error,
  validation,
});

export const deleteZoneRequest = (id: number) => ({
  type: DELETE_ZONE_REQUEST,
  id,
});

export const deleteZoneSuccess = (id: number) => ({
  type: DELETE_ZONE_SUCCESS,
  id,
});

export const deleteZoneFailure = (error: unknown) => ({
  type: DELETE_ZONE_FAILURE,
  error,
});

export const selectZoneWithCtrl = (id: number | string) => ({
  type: SELECT_ZONE_WITH_CTRL,
  id,
});

export const selectZoneWithShift = (id: number | string) => ({
  type: SELECT_ZONE_WITH_SHIFT,
  id,
});

export const selectZoneWithout = (id: number | string) => ({
  type: SELECT_ZONE_WITHOUT,
  id,
});

export const selectZoneAll = () => ({
  type: SELECT_ZONE_ALL,
});

export const clearZoneSelections = () => ({
  type: CLEAR_ZONE_SELECTIONS,
});
