import clsx from 'clsx';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateOrganizationRequest } from '../../../actions/organizations';
import {
  Button,
  Input,
  Label,
  MultiSelect,
  Select,
} from '../../../components/form';
import {
  ORGANIZATION_SIZE_VALUES,
  ORGANIZATION_SIZES,
} from '../../../constants/organization';
import { organizationalTypeIds } from '../../../constants/organizational-type';
import { ADMIN, SUPERUSER, VIEWER } from '../../../constants/roles';
import { industriesSelector } from '../../../selectors/industry';
import {
  activeOrganizationSelector,
  updatingOrganizationSelector,
} from '../../../selectors/organizations';
import { userRoleSelector } from '../../../selectors/users';
import { updateOrganizationValidationSelector } from '../../../selectors/validations';
import styles from './information.module.css';

function Information() {
  const [t] = useTranslation('organization', { keyPrefix: 'information' });

  const [t2] = useTranslation();

  const dispatch = useDispatch();

  const role = useSelector(userRoleSelector);

  const organization = useSelector(activeOrganizationSelector);

  const industries = useSelector(industriesSelector);

  const validation = useSelector(updateOrganizationValidationSelector);

  const updating = useSelector(updatingOrganizationSelector);

  const [name, setName] = useState(organization.short_name);

  const [number, setNumber] = useState(organization.number);

  const [locationAmount, setLocationAmount] = useState(
    organization.locations_amount
      ? {
          label: organization.locations_amount,
          value: ORGANIZATION_SIZE_VALUES[organization.locations_amount],
        }
      : null
  );

  const initialIndustries = useMemo(
    () =>
      organization.industries.map((i) => ({
        label: t2(`industries.${i.name}`),
        value: i.id,
      })),
    [organization, t2]
  );

  const [selectedIndustries, setSelectedIndustries] =
    useState(initialIndustries);

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handleNumberChange = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const handleSetSelectedIndustries = useCallback(
    (option: Option) => {
      const { value } = option;
      const index = selectedIndustries.findIndex(
        (i) => String(i.value) === value
      );
      let nextValue = [];

      if (index === -1) {
        nextValue = [...selectedIndustries, option];
        setSelectedIndustries(nextValue);
      } else {
        nextValue = [
          ...selectedIndustries.slice(0, index),
          ...selectedIndustries.slice(index + 1),
        ];
        setSelectedIndustries(nextValue);
      }
    },
    [selectedIndustries]
  );

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      const payload = {
        industries: selectedIndustries.map((i) => i.value),
        locations_amount: locationAmount?.value,
        number,
        short_name: name,
      };

      dispatch(updateOrganizationRequest(payload));
    },
    [dispatch, locationAmount, name, number, selectedIndustries]
  );

  // const handleDeleteOrganizationClick = useCallback(
  //   () => {
  //     dispatch(showModal(DELETE_ORGANIZATION_MODAL, {
  //       size: 'sm',
  //       backgroundColor: 'var(--error)',
  //       organization,
  //     }));
  //   },
  //   [dispatch, organization]
  // );

  useEffect(() => {
    setName(organization.short_name);
    setNumber(organization.number);
    setLocationAmount(
      organization.locations_amount
        ? {
            label: organization.locations_amount,
            value: ORGANIZATION_SIZE_VALUES[organization.locations_amount],
          }
        : null
    );
    setSelectedIndustries(initialIndustries);
  }, [initialIndustries, organization]);

  // const submitDisabled = useMemo(
  //   () =>
  //     name !== organization.short_name ||
  //     number !== organization.number ||
  //     locationAmount?.value !== organization.location_amount ||
  //     selectedIndustries !== initialIndustries ||

  //   []
  // );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <section className={styles.section}>
        <div className={styles.column}>
          <Label className={clsx(styles.label, styles.disabled)}>
            {t('type')}
            <Input
              containerClass={styles.disabledInput}
              disabled
              name="type"
              type="text"
              value={t2(
                `organizationType.${
                  organizationalTypeIds[organization.type || 0]
                }`
              )}
            />
          </Label>
          <Label className={styles.label}>
            {t('name')}
            <Input
              disabled={role === VIEWER}
              name="short_name"
              onChange={handleNameChange}
              placeholder={t('namePlaceholder')}
              type="text"
              validation={validation}
              validationKey="updateOrganization"
              value={name}
            />
          </Label>
          <Label className={styles.label}>
            {t('number')}
            <Input
              disabled={role === VIEWER}
              id="organization-name"
              name="number"
              onChange={handleNumberChange}
              placeholder={t('numberPlaceholder')}
              type="text"
              validation={validation}
              validationKey="updateOrganization"
              value={number}
            />
          </Label>
          {/* <div className={styles.delete}>
          <SvgTrash width={14} height={16} />
          <span
            className={styles.action}
            onClick={handleDeleteOrganizationClick}
          >
            {t('delete')}
          </span>
        </div> */}
        </div>
        <div className={styles.column}>
          <Label className={styles.label}>
            {t('locationAmount')}
            <Select
              dashed
              disabled={role === VIEWER}
              name="locations_amount"
              options={Object.entries(ORGANIZATION_SIZES).map(
                ([key, value]) => ({
                  label: value,
                  value: key,
                })
              )}
              placeholder={t('locationAmountPlaceholder')}
              selected={locationAmount}
              setSelected={setLocationAmount}
              validation={validation}
              validationKey="updateOrganization"
              zIndex={2}
            />
          </Label>
          <Label className={styles.label}>
            {t('industry')}
            <MultiSelect
              disabled={role === VIEWER}
              name="industries"
              options={industries.map((i) => ({
                label: t2(`industries.${i.name}`),
                value: i.id.toString(),
              }))}
              placeholder={t('industryPlaceholder')}
              selected={selectedIndustries}
              setSelected={handleSetSelectedIndustries}
              validation={validation}
              validationKey="updateOrganization"
              zIndex={1}
            />
          </Label>
        </div>
      </section>
      {(role === SUPERUSER || role === ADMIN) && (
        <div className={styles.footer}>
          <Button color="secondary" loading={updating} type="submit">
            {t('apply')}
          </Button>
        </div>
      )}
    </form>
  );
}

export default Information;
