import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateCameraRequest } from '../../../actions/cameras';
import { foldoutStateChanged } from '../../../actions/foldouts';
import { showModal } from '../../../actions/modals';
import {
  Button,
  ButtonGroup,
  HiddenTextArea,
  Input,
  Label,
  MultiSelect,
  Select,
  Toggle,
} from '../../../components/form';
import {
  CanvasButton,
  ColorSelect,
  CounterUrl,
  FoldoutAction,
  FoldoutLine,
} from '../../../components/miscellaneous';
import {
  SvgArrowRight,
  SvgCopy,
  SvgDashedLine,
  SvgDuplicate,
  SvgEdit,
  SvgLine,
  SvgRedo,
  SvgTip,
  SvgTrash,
  SvgTrashLight,
  SvgUndo,
} from '../../../components/svg';
import {
  ARROW_LENGTH,
  ARROW_OFFSET,
  LINE_HEIGHT,
  LINE_WIDTH,
} from '../../../constants/canvas';
import floors from '../../../constants/floors';
import {
  COLOR_CHANGE,
  DELETION,
  GENERATION,
  TRANSFORMATION,
} from '../../../constants/history-types';
import lineTypes from '../../../constants/line-types';
import {
  ACTIVATE_CAMERA_MODAL,
  DELETE_CAMERA_MODAL,
} from '../../../constants/modal-types';
import { SUPERUSER } from '../../../constants/roles';
import { RootState } from '../../../reducers';
import {
  cameraByIdSelector,
  updatingCameraSelector,
} from '../../../selectors/cameras';
import { categoriesSelector } from '../../../selectors/categories';
import { userRoleSelector, userSelector } from '../../../selectors/users';
import { updateCameraValidationSelector } from '../../../selectors/validations';
import { zonesSelector } from '../../../selectors/zones';
import { ICamera } from '../../../types/api/cameras';
import { IArrow, ILineWithZoneIds } from '../../../types/api/lines';
import { IZone } from '../../../types/api/zones';
import { calculateArrowPos } from '../../../utils/canvas';
import { uuid4 } from '../../../utils/helper';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import Canvas from './canvas';
import styles from './index.module.css';
import { IMark } from './mark';

interface History {
  mark: IMark;
  type: string;
}

type EditCameraProps = {
  close: () => void;
  id: ICamera['id'];
};

function EditCamera(props: EditCameraProps) {
  const { close, id } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'editCamera' });

  const [t2] = useTranslation();

  const areaLayer = useRef(null);

  const camera = useSelector((state: RootState) =>
    cameraByIdSelector(state, id)
  );

  const categories = useSelector(categoriesSelector);

  const allZones: IZone[] = useSelector(zonesSelector);

  const zones = useMemo(
    () =>
      allZones.filter(
        (z) =>
          z.organization === null ||
          z.organization === camera.location.organization.id
      ),
    [allZones, camera]
  );

  const user = useSelector(userSelector);

  const submitting = useSelector(updatingCameraSelector);

  const validation = useSelector(updateCameraValidationSelector);

  const role = useSelector(userRoleSelector);

  const [ip, setIp] = useState(camera.ip);

  const [port, setPort] = useState(camera.port);

  const [name, setName] = useState(camera.name);

  const [url, setUrl] = useState(camera.stream_location);

  const [model, setModel] = useState(camera.model);

  const [login, setLogin] = useState(camera.login);

  const [password, setPassword] = useState(camera.password);

  const [floor, setFloor] = useState(
    camera.floor || camera.floor === 0
      ? {
          label: t2(`floors.${camera.floor}`),
          value: camera.floor.toString(),
        }
      : null
  );

  const [isActive, setIsActive] = useState(camera.is_active);

  const [historyStep, setHistoryStep] = useState(-1);

  const [marks, setMarks] = useState<IMark[]>(camera.zones);

  const [selectedShape, setSelectedShape] = useState<null | string>(null);

  const [info, setInfo] = useState<any>({});

  const [history, setHistory] = useState<History[]>([]);

  const [zoneFrom, setZoneFrom] = useState(null);
  const [zoneTo, setZoneTo] = useState(null);

  const [zoneFrom0, setZoneFrom0] = useState(null);
  const [zoneTo0, setZoneTo0] = useState(null);

  const [zoneFrom1, setZoneFrom1] = useState(null);
  const [zoneTo1, setZoneTo1] = useState(null);

  const [zoneFrom2, setZoneFrom2] = useState(null);
  const [zoneTo2, setZoneTo2] = useState(null);

  const [category, setCategory] = useState<Option>(null);

  const [lineType, setLineType] = useState<Option>(null);

  const [color, setColor] = useState<string>('#DB644F');

  const [markingName, setMarkingName] = useState('');

  const [drawing, setDrawing] = useState(false);

  const selectedMark = useMemo(
    () => marks.find((m) => m.markerId === selectedShape),
    [marks, selectedShape]
  );

  const relatedParentMark = useMemo(
    () => marks.find((m) => m.markerId === selectedMark?.parentId),
    [marks, selectedMark]
  );

  const handleIpChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIp(e.target.value);
  }, []);

  const handlePortChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPort(e.target.value);
  }, []);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  const handleModelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  }, []);

  const handleLoginChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  }, []);

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleMarkNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setMarkingName(e.target.value);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = { ...marks[index], name: e.target.value };

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [marks, selectedShape]
  );

  const handleIsActiveChange = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const handleMarkNameBlur = useCallback(() => {
    const index = marks.findIndex((m) => m.markerId === selectedShape);

    const mark = { ...marks[index], name: markingName };

    setHistory(
      history.slice(0, historyStep + 1).concat({
        mark,
        type: TRANSFORMATION,
      })
    );

    setHistoryStep(historyStep + 1);

    setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
  }, [history, historyStep, markingName, marks, selectedShape]);

  const handleLineTypeChange = useCallback(
    (option: Option) => {
      setLineType(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        type: lineTypes[option?.value] || null,
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  // TODO: Remove category handler if not needed.
  // const handleCategoryChange = useCallback(
  //   (option: Option) => {
  //     setCategory(option);

  //     const index = marks.findIndex((m) => m.markerId === selectedShape);

  //     const mark = {
  //       ...marks[index],
  //       category: option?.value ? parseInt(option.value) : null,
  //     };

  //     setHistory([
  //       ...history,
  //       {
  //         mark,
  //         type: TRANSFORMATION,
  //       },
  //     ]);

  //     setHistoryStep(historyStep + 1);

  //     setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
  //   },
  //   [history, historyStep, marks, selectedShape]
  // );

  const handleZoneTo0Change = useCallback(
    (option: Option) => {
      setZoneTo0(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          {
            FromZone: marks[index].lineZones[0]?.FromZone ?? null,
            ToZone: option?.value ? parseInt(option.value) : null,
          },
          marks[index].lineZones[1],
          marks[index].lineZones[2],
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneFrom0Change = useCallback(
    (option: Option) => {
      setZoneFrom0(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          {
            FromZone: option?.value ? parseInt(option.value) : null,
            ToZone: marks[index].lineZones[0]?.ToZone ?? null,
          },
          marks[index].lineZones[1],
          marks[index].lineZones[2],
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneTo1Change = useCallback(
    (option: Option) => {
      setZoneTo1(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          marks[index].lineZones[0],
          {
            FromZone: marks[index].lineZones[1]?.FromZone ?? null,
            ToZone: option?.value ? parseInt(option.value) : null,
          },
          marks[index].lineZones[2],
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneFrom1Change = useCallback(
    (option: Option) => {
      setZoneFrom1(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          marks[index].lineZones[0],
          {
            FromZone: option?.value ? parseInt(option.value) : null,
            ToZone: marks[index].lineZones[1]?.ToZone ?? null,
          },
          marks[index].lineZones[2],
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneTo2Change = useCallback(
    (option: Option) => {
      setZoneTo2(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          marks[index].lineZones[0],
          marks[index].lineZones[1],
          {
            FromZone: marks[index].lineZones[2]?.FromZone ?? null,
            ToZone: option?.value ? parseInt(option.value) : null,
          },
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneFrom2Change = useCallback(
    (option: Option) => {
      setZoneFrom2(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        lineZones: [
          marks[index].lineZones[0],
          marks[index].lineZones[1],
          {
            FromZone: option?.value ? parseInt(option.value) : null,
            ToZone: marks[index].lineZones[2]?.ToZone ?? null,
          },
        ],
      };

      setHistory([
        ...history,
        {
          mark,
          type: TRANSFORMATION,
        },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneFromChange = useCallback(
    (option: Option) => {
      setZoneFrom(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        zoneFrom: option?.value ? parseInt(option.value) : null,
      };

      setHistory([
        ...history.slice(0, historyStep + 1),
        { mark, type: TRANSFORMATION },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks, selectedShape]
  );

  const handleZoneToChange = useCallback(
    (option: Option) => {
      setZoneTo(option);

      const index = marks.findIndex((m) => m.markerId === selectedShape);

      const mark = {
        ...marks[index],
        zoneTo: option?.value ? parseInt(option.value) : null,
      };

      setHistory([
        ...history.slice(0, historyStep + 1),
        { mark, type: TRANSFORMATION },
      ]);

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },

    [history, historyStep, marks, selectedShape]
  );

  const handleSelectedShapeChange = useCallback((marking: IMark | string) => {
    // This setTimeout is to delay shape selection because "handleMarkNameBlur"
    // should be called first (before selectedShape getting a new value).
    setTimeout(() => {
      if (!marking) {
        setSelectedShape(null);

        return;
      }

      if (typeof marking === 'string' || typeof marking === 'number') {
        // If string/number then it is the markerId of the marking.
        // This condition occurs only when it is selected by canvas interaction.

        setSelectedShape(marking);
      } else {
        setSelectedShape(marking.markerId);
      }
    }, 0);
  }, []);

  const handleGenerateLineClick = useCallback(() => {
    const mark: IMark = {
      accuracyIndex: '',
      category: null,
      createdAt: new Date().getTime(),
      createdBy: { email: user.email, id: user.id },
      fill: 'white',
      lineZones: [],
      markerId: uuid4(),
      name: t('untitled'),
      parentId: null,
      points: [0, 0, LINE_WIDTH, 0],
      stroke: color,
      strokeWidth: LINE_HEIGHT,
      totalVisitors: 0,
      type: null,
      x: 390 - LINE_WIDTH / 2,
      y: 219 - LINE_HEIGHT,
      zoneFrom: null,
      zoneTo: null,
    };

    setHistory(
      history.slice(0, historyStep + 1).concat({
        mark,
        type: GENERATION,
      })
    );

    setHistoryStep(historyStep + 1);

    setMarks([...marks, mark]);

    handleSelectedShapeChange(mark);
  }, [
    color,
    t,
    user.id,
    user.email,
    history,
    historyStep,
    marks,
    handleSelectedShapeChange,
  ]);

  const handleGenerateBufferLineClick = useCallback(() => {
    const parentMark = marks.find((m) => m.markerId === selectedShape);
    const bufferCount = marks.filter(
      (m) => m.parentId === selectedShape
    ).length;

    const mark: IMark = {
      accuracyIndex: '',
      category: null,
      createdAt: new Date().getTime(),
      createdBy: { email: user.email, id: user.id },
      fill: 'white',
      lineZones: [],
      markerId: uuid4(),
      name: `Buffer ${bufferCount + 1}`,
      parentId: parentMark.markerId,
      points: [0, 0, LINE_WIDTH, 0],
      stroke: parentMark.stroke,
      strokeWidth: LINE_HEIGHT,
      totalVisitors: 0,
      type: null,
      x: 390 - LINE_WIDTH / 2,
      y: 219 - LINE_HEIGHT,
      zoneFrom: null,
      zoneTo: null,
    };

    setHistory(
      history.slice(0, historyStep + 1).concat({
        mark,
        type: GENERATION,
      })
    );

    setHistoryStep(historyStep + 1);

    setMarks([...marks, mark]);

    handleSelectedShapeChange(mark);
  }, [
    marks,
    user,
    history,
    historyStep,
    handleSelectedShapeChange,
    selectedShape,
  ]);

  const handleDuplicateClick = useCallback(() => {
    const { id: skip, ...prevMark } = marks.find(
      (m) => m.markerId === selectedShape
    );

    const mark = {
      ...prevMark,
      markerId: uuid4(),
      x: 390 - LINE_WIDTH / 2,
      y: 219 - LINE_HEIGHT,
    };

    const bufferCount = marks.filter(
      (m) => m.parentId === mark.parentId
    ).length;

    if (mark.parentId) {
      mark.name = `Buffer ${bufferCount + 1}`;
    }

    setHistory(
      history.slice(0, historyStep + 1).concat({
        mark,
        type: GENERATION,
      })
    );

    setHistoryStep(historyStep + 1);

    setMarks([...marks, mark]);

    handleSelectedShapeChange(mark);
  }, [marks, history, historyStep, handleSelectedShapeChange, selectedShape]);

  const handleDeleteMarkClick = useCallback(() => {
    setHistory(
      history.slice(0, historyStep + 1).concat({
        mark: selectedMark,
        type: DELETION,
      })
    );

    setHistoryStep(historyStep + 1);

    setMarks(
      marks.filter(
        (m) => m.markerId !== selectedShape && m.parentId !== selectedShape
      )
    );

    handleSelectedShapeChange(selectedMark.parentId);
  }, [
    handleSelectedShapeChange,
    history,
    historyStep,
    marks,
    selectedMark,
    selectedShape,
  ]);

  const handleMarkChange = useCallback(
    (mark: IMark) => {
      const index = marks.findIndex((m) => m.markerId === mark.markerId);

      setHistory(
        history.slice(0, historyStep + 1).concat({
          mark,
          type: TRANSFORMATION,
        })
      );

      setHistoryStep(historyStep + 1);

      setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
    },
    [history, historyStep, marks]
  );

  const handleUndoClick = useCallback(() => {
    if (historyStep === -1) return;

    const { type } = history[historyStep];

    switch (type) {
      case GENERATION: {
        const { mark } = history[historyStep];

        const index = marks.findIndex((m) => m.markerId === mark.markerId);

        setMarks([...marks.slice(0, index), ...marks.slice(index + 1)]);

        setHistoryStep(historyStep - 1);

        handleSelectedShapeChange(mark.parentId);

        break;
      }
      case DELETION: {
        const { mark } = history[historyStep];

        setMarks([...marks, mark]);

        setHistoryStep(historyStep - 1);

        handleSelectedShapeChange(mark);

        break;
      }
      case COLOR_CHANGE: {
        if (historyStep === 0) {
          // Marking data should be set to initialization state.
          setMarks(camera.zones);
          handleSelectedShapeChange(null);
        } else {
          const { mark } = history[historyStep - 1];

          const newMarks = marks.map((m) => {
            if (m.markerId === mark.markerId) {
              return mark;
            }

            if (!mark.parentId && m.parentId === mark.markerId) {
              return { ...m, stroke: mark.stroke };
            }

            return m;
          });

          setMarks(newMarks);

          handleSelectedShapeChange(mark);
        }

        setHistoryStep(historyStep - 1);

        break;
      }
      default: {
        if (historyStep === 0) {
          // Marking data should be set to initialization state.
          setMarks(camera.zones);
          handleSelectedShapeChange(null);
        } else {
          const { mark } = history[historyStep - 1];

          const index = marks.findIndex((m) => m.markerId === mark.markerId);

          setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);

          handleSelectedShapeChange(mark);
        }

        setHistoryStep(historyStep - 1);
      }
    }
  }, [camera, handleSelectedShapeChange, history, historyStep, marks]);

  const handleRedoClick = useCallback(() => {
    if (historyStep === history.length - 1) return;

    const { mark, type } = history[historyStep + 1];

    switch (type) {
      case DELETION: {
        const index = marks.findIndex((m) => m.markerId === mark.markerId);

        setMarks([...marks.slice(0, index), ...marks.slice(index + 1)]);

        setHistoryStep(historyStep + 1);

        handleSelectedShapeChange(mark.parentId);

        break;
      }
      case COLOR_CHANGE: {
        const newMarks = marks.map((m) => {
          if (m.markerId === mark.markerId) {
            return mark;
          }

          if (!mark.parentId && m.parentId === mark.markerId) {
            return { ...m, stroke: mark.stroke };
          }

          return m;
        });

        setMarks(newMarks);

        setHistoryStep(historyStep + 1);

        handleSelectedShapeChange(mark);

        break;
      }
      default: {
        const index = marks.findIndex((m) => m.markerId === mark.markerId);

        if (index !== -1) {
          setMarks([...marks.slice(0, index), mark, ...marks.slice(index + 1)]);
        } else {
          setMarks([...marks, mark]);
        }

        setHistoryStep(historyStep + 1);

        handleSelectedShapeChange(mark);
      }
    }
  }, [handleSelectedShapeChange, history, historyStep, marks]);

  const handleTipClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    console.log('Tip clicked!', e);
  }, []);

  const handleColorChange = useCallback(
    (newColor: string) => {
      // const index = marks.findIndex((m) => m.markerId === selectedShape);
      if (selectedMark) {
        const newMarks = marks.map((m) => {
          if (m.markerId === selectedMark.markerId) {
            return {
              ...m,
              stroke: newColor,
            };
          }

          if (!selectedMark.parentId && m.parentId === selectedMark.markerId) {
            return {
              ...m,
              stroke: newColor,
            };
          }

          return m;
        });

        setHistory(
          history.slice(0, historyStep + 1).concat(
            {
              mark: selectedMark,
              type: COLOR_CHANGE,
            },
            {
              mark: { ...selectedMark, stroke: newColor },
              type: COLOR_CHANGE,
            }
          )
        );

        setHistoryStep(historyStep + 2);

        setMarks(newMarks);
      }

      setColor(newColor);
    },
    [history, historyStep, marks, selectedMark]
  );

  const handleStartAreaDrawing = useCallback(() => {
    setDrawing(true);
    setSelectedShape(null);
  }, []);

  const bufferSelected = useMemo(() => {
    const mark = marks.find((m) => m.markerId === selectedShape);

    return mark && !!mark.parentId;
  }, [marks, selectedShape]);

  const [visible, setVisible] = useState(false);

  const textArea = useRef(null);

  const timer = useRef<NodeJS.Timeout>();

  const prevIsActive = useRef(true);

  const handleCopyId = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    setVisible(true);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(selectedShape);
    } else {
      textArea.current.value = selectedShape;
      textArea.current.select();
      document.execCommand('copy');
      textArea.current.blur();
    }

    timer.current = setTimeout(() => setVisible(false), 2500);
  }, [selectedShape]);

  const handleDeleteClick = useCallback(() => {
    dispatch(
      showModal(DELETE_CAMERA_MODAL, {
        backgroundColor: 'var(--error)',
        camera,
        size: 'sm',
      })
    );
  }, [dispatch, camera]);

  const handleSubmit = useCallback(() => {
    const payload: Partial<Omit<ICamera, 'zones'>> & {
      zones: ILineWithZoneIds[];
    } = {
      floor: floor ? parseInt(floor.value) : null,
      ip,
      is_active: isActive,
      login: login,
      model: model,
      name,
      password: password,
      port,
      stream_location: url,
      zones: marks.map((m) => {
        const [arrowX2, arrowY2] = calculateArrowPos(m.points, ARROW_OFFSET);

        const [arrowX1, arrowY1] = calculateArrowPos(
          m.points,
          ARROW_LENGTH + ARROW_OFFSET
        );

        return {
          arrow: {
            x1: arrowX1.toString(),
            x2: arrowX2.toString(),
            y1: arrowY1.toString(),
            y2: arrowY2.toString(),
          } as IArrow,
          camera: camera.id,
          category: m.category ?? null,
          colour: m.stroke,
          id: m.id,
          line_zones:
            m.lineZones.filter(
              (lz) => lz && lz.FromZone != null && lz.ToZone != null
            ) || [],
          marker_id: m.markerId,
          name: m.name,
          parent_marker_id: m.parentId ?? null,
          type: m.type,
          x1: m.x.toString(),
          x2: (m.points[2] + m.x).toString(),
          y1: m.y.toString(),
          y2: (m.points[3] + m.y).toString(),
          zone_from: m.zoneFrom ?? null,
          zone_to: m.zoneTo ?? null,
        } as ILineWithZoneIds;
      }),
    };

    dispatch(updateCameraRequest(id, payload));
  }, [
    camera.id,
    dispatch,
    floor,
    id,
    ip,
    isActive,
    login,
    marks,
    model,
    name,
    password,
    port,
    url,
  ]);

  const isUpdated = useMemo(
    () =>
      historyStep !== -1 ||
      isActive !== camera.is_active ||
      floor?.value !== camera.floor?.toString() ||
      password !== camera.password ||
      login !== camera.login ||
      model !== camera.model ||
      url !== camera.stream_location ||
      port !== camera.port ||
      ip !== camera.ip ||
      name !== camera.name,
    // add here as the ne state props added
    [
      camera,
      floor,
      historyStep,
      ip,
      isActive,
      login,
      model,
      name,
      password,
      port,
      url,
    ]
  );

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  // Manages updates on selectShape change.
  useEffect(() => {
    const mark = selectedMark;

    if (mark) {
      const zf = zones.find((z) => z.id === mark.zoneFrom);
      const zt = zones.find((z) => z.id === mark.zoneTo);
      const zf0 = zones.find((z) => z.id === mark.lineZones[0]?.FromZone);
      const zt0 = zones.find((z) => z.id === mark.lineZones[0]?.ToZone);
      const zf1 = zones.find((z) => z.id === mark.lineZones[1]?.FromZone);
      const zt1 = zones.find((z) => z.id === mark.lineZones[1]?.ToZone);
      const zf2 = zones.find((z) => z.id === mark.lineZones[2]?.FromZone);
      const zt2 = zones.find((z) => z.id === mark.lineZones[2]?.ToZone);
      const cat = categories.find((c) => c.id === mark.category);
      const lt = Object.entries(lineTypes).find(([, v]) => v === mark.type);

      setMarkingName(mark.name);
      setColor(mark.stroke);
      setCategory(
        cat
          ? {
              label: cat.name,
              value: cat.id.toString(),
            }
          : null
      );
      setLineType(
        lt
          ? {
              label: t2(`lineTypes.${lt[1]}`),
              value: mark.type.toString(),
            }
          : null
      );
      setZoneFrom(
        zf
          ? {
              label: zf.name,
              value: zf.id,
            }
          : null
      );
      setZoneTo(
        zt
          ? {
              label: zt.name,
              value: zt.id,
            }
          : null
      );
      setZoneFrom0(
        zf0
          ? {
              label: zf0.name,
              value: zf0.id,
            }
          : null
      );
      setZoneTo0(
        zt0
          ? {
              label: zt0.name,
              value: zt0.id,
            }
          : null
      );
      setZoneFrom1(
        zf1
          ? {
              label: zf1.name,
              value: zf1.id,
            }
          : null
      );
      setZoneTo1(
        zt1
          ? {
              label: zt1.name,
              value: zt1.id,
            }
          : null
      );
      setZoneFrom2(
        zf2
          ? {
              label: zf2.name,
              value: zf2.id,
            }
          : null
      );
      setZoneTo2(
        zt2
          ? {
              label: zt2.name,
              value: zt2.id,
            }
          : null
      );
      setInfo({
        accuracyIndex: mark.accuracyIndex,
        createdAt: mark.createdAt,
        createdBy: mark.createdBy,
        totalVisitors: mark.totalVisitors,
      });
    } else {
      setLineType(null);
      setCategory(null);
      setInfo({});
      setMarkingName('');
      setZoneFrom(null);
      setZoneTo(null);
      setZoneFrom0(null);
      setZoneTo0(null);
      setZoneFrom1(null);
      setZoneTo1(null);
      setZoneFrom2(null);
      setZoneTo2(null);
    }
  }, [categories, selectedMark, t2, zones]);

  useEffect(() => {
    if (!prevIsActive.current && isActive) {
      dispatch(
        showModal(ACTIVATE_CAMERA_MODAL, {
          backgroundColor: 'var(--positive)',
          deactivate: () => setIsActive(false),
          size: 'sm',
        })
      );
    }

    prevIsActive.current = isActive;
  }, [dispatch, isActive]);

  // Manages updates on validation change.
  useEffect(() => {
    const val = validation.find((v) => !v.field);

    if (val) {
      // TODO: Implement foldout toast which will be shown above the footer.
    }
  }, [validation]);

  return (
    <>
      <HiddenTextArea ref={textArea} />
      <Header icon={<SvgEdit height={20} width={20} />} title={t('title')}>
        <div className={styles.id}>
          <Trans
            components={{
              bold: <b />,
            }}
            i18nKey="id"
            t={t}
            values={{ id }}
          />
        </div>
      </Header>
      <Main className={styles.foldout}>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.name')}
            <Input
              dashed={false}
              name="name"
              onChange={handleNameChange}
              placeholder={t('placeholder.name')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={name}
            />
          </Label>
          <Label className={styles.label}>
            {t('label.model')}
            <Input
              dashed={false}
              name="model"
              onChange={handleModelChange}
              placeholder={t('placeholder.model')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={model}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.floor')}
            <Select
              dashed={false}
              name="floor"
              options={floors.map((f) => ({
                label: t2(`floors.${f}`),
                value: f.toString(),
              }))}
              placeholder={t('placeholder.floor')}
              selected={floor}
              setSelected={setFloor}
              validation={validation}
              validationKey="createCamera"
              zIndex={1}
            />
          </Label>
          <Label className={clsx([styles.label, styles.inline])}>
            <div className={styles.status}>{t('label.status')}</div>
            <Toggle
              checked={isActive}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ignoreid="true"
              onChange={handleIsActiveChange}
              parentClass={styles.toggle}
              // disabled
              // readOnly
              prefix={{
                off: t('deactivated'),
                on: t('activated'),
              }}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.login')}
            <Input
              autoComplete="off"
              dashed={false}
              name="camera-login"
              onChange={handleLoginChange}
              placeholder={t('placeholder.login')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={login}
            />
          </Label>
          <Label className={styles.label}>
            {t('label.password')}
            <Input
              autoComplete="off"
              dashed={false}
              name="camera-password"
              onChange={handlePasswordChange}
              placeholder={t('placeholder.password')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={password}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.ip')}
            <Input
              blackDisabled
              dashed={false}
              name="ip"
              onChange={handleIpChange}
              placeholder={t('placeholder.ip')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={ip}
            />
          </Label>

          <Label className={styles.label}>
            {t('label.port')}
            <Input
              autoComplete="off"
              dashed={false}
              name="port"
              onChange={handlePortChange}
              placeholder={t('placeholder.port')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={port}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.url')}
            <Input
              dashed={false}
              name="stream_location"
              onChange={handleUrlChange}
              placeholder={t('placeholder.url')}
              type="text"
              validation={validation}
              validationKey="updateCamera"
              value={url}
            />
          </Label>
          <CounterUrl
            className={styles.fullUrl}
            ip={ip}
            password={password}
            port={port}
            url={url}
            username={login}
          />
        </div>
        <div className={styles.row}>
          <span className={styles.uploadDate}>
            {camera.last_image_upload_date
              ? t('uploadDate', {
                  date: dayjs(camera.last_image_upload_date).format(
                    'YYYY-MM-DD HH:MM'
                  ),
                })
              : ''}
          </span>
        </div>
        <div className={clsx(styles.row, styles.noTopMargin)}>
          <Canvas
            marks={marks}
            onMarkChange={handleMarkChange}
            ref={areaLayer}
            selectedShape={selectedShape}
            setSelectedShape={handleSelectedShapeChange}
            snapshot={camera.last_image_url}
          />
        </div>
        <div className={clsx([styles.row, styles.topless])}>
          <div className={styles.left}>
            <CanvasButton
              disabled={historyStep === -1 || drawing}
              icon={<SvgUndo />}
              onClick={handleUndoClick}
            />
            <CanvasButton
              disabled={
                history.length === 0 ||
                historyStep === history.length - 1 ||
                drawing
              }
              icon={<SvgRedo />}
              onClick={handleRedoClick}
            />
          </div>
          <div className={styles.middle}>
            <CanvasButton
              disabled={drawing}
              icon={<SvgLine height={18} width={18} />}
              label={t('canvas.line')}
              onClick={handleGenerateLineClick}
            />
            <CanvasButton
              disabled={drawing || !selectedShape || bufferSelected}
              icon={<SvgDashedLine height={18} width={18} />}
              label={t('canvas.buffer')}
              onClick={handleGenerateBufferLineClick}
            />
            {/* <CanvasButton
              active={drawing}
              icon={<SvgArea width={18} height={18} />}
              label={t('canvas.area')}
              onClick={handleStartAreaDrawing}
            /> */}
            <CanvasButton
              disabled={!selectedShape || drawing}
              icon={<SvgDuplicate height={14} width={24} />}
              label={t('canvas.duplicate')}
              onClick={handleDuplicateClick}
            />
            <CanvasButton
              disabled={!selectedShape}
              icon={<SvgTrash height={16} width={15} />}
              label={t('canvas.delete')}
              onClick={handleDeleteMarkClick}
            />
          </div>
          <div className={styles.right}>
            <div
              className={styles.tip}
              onClick={handleTipClick}
              role="link"
              tabIndex={0}
            >
              {t('help')}
              <SvgTip height={24} width={24} />
            </div>
          </div>
        </div>
        <FoldoutLine />
        {bufferSelected ? (
          <>
            <div className={styles.row}>
              <Label className={styles.label}>
                {t('label.parentName')}
                <Input
                  blackDisabled
                  dashed={false}
                  disabled
                  maxLength={15}
                  name="parent-name"
                  onBlur={handleMarkNameBlur}
                  onChange={handleMarkNameChange}
                  type="text"
                  value={relatedParentMark?.name}
                />
              </Label>
              <Label className={styles.label}>
                {t('label.markingName')}
                <Input
                  blackDisabled
                  dashed={false}
                  disabled={!selectedShape}
                  maxLength={15}
                  name="marking-name"
                  onBlur={handleMarkNameBlur}
                  onChange={handleMarkNameChange}
                  placeholder={t('placeholder.markingName')}
                  type="text"
                  value={markingName}
                />
              </Label>
            </div>
            <div className={clsx([styles.row, styles.aligned])}>
              <Label className={styles.label}>
                {t('label.color')}
                <ColorSelect
                  colors={[
                    '#78BA5D',
                    '#EED647',
                    '#F3A240',
                    '#DB644F',
                    '#B87EDA',
                    '#3279BA',
                    '#55C0DC',
                    '#7FE49F',
                    '#EE82C8',
                    '#374661',
                  ]}
                  disabled
                  selected={color}
                  setSelected={handleColorChange}
                />
              </Label>
              <Label className={styles.label}>
                {t('label.information')}
                <div className={styles.infoWrapper}>
                  <div className={styles.info}>
                    <Trans
                      components={{ bold: <b /> }}
                      i18nKey="information.creation"
                      t={t}
                      values={{
                        creator: info?.createdBy?.email || '-',
                        date: info.createdAt
                          ? dayjs(info.createdAt).format('YYYY.MM.DD')
                          : '-',
                      }}
                    />
                  </div>
                  <div className={styles.info}>
                    <Trans
                      components={{ bold: <b /> }}
                      i18nKey="information.id"
                      t={t}
                      values={{ value: selectedShape || '-' }}
                    />
                    {selectedShape && (
                      <Tippy
                        content={
                          <div className={styles.copyTooltip}>
                            {t('information.copied')}
                          </div>
                        }
                        delay={[0, 0]}
                        onClickOutside={() => setVisible(false)}
                        placement="right"
                        theme="light-border"
                        visible={visible}
                      >
                        <SvgCopy
                          height={18}
                          onClick={handleCopyId}
                          width={18}
                        />
                      </Tippy>
                    )}
                  </div>
                </div>
              </Label>
            </div>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <Label className={styles.label}>
                {t('label.zoneFrom')}
                <Select
                  dashed={false}
                  disabled={!selectedShape}
                  options={zones.map((z) => ({
                    label: z.name,
                    value: z.id.toString(),
                  }))}
                  placeholder={t('placeholder.zone')}
                  selected={zoneFrom}
                  setSelected={handleZoneFromChange}
                  zIndex={6}
                />
              </Label>
              <div className={styles.arrow}>
                <SvgArrowRight height={16} width={16} />
              </div>
              <Label className={styles.label}>
                {t('label.zoneTo')}
                <Select
                  dashed={false}
                  disabled={!selectedShape}
                  options={zones.map((z) => ({
                    label: z.name,
                    value: z.id.toString(),
                  }))}
                  placeholder={t('placeholder.zone')}
                  selected={zoneTo}
                  setSelected={handleZoneToChange}
                  zIndex={6}
                />
              </Label>
            </div>
            {role === SUPERUSER && (
              <>
                <div className={styles.row}>
                  <Label className={styles.label}>
                    {t('label.zoneFrom')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneFrom0}
                      setSelected={handleZoneFrom0Change}
                      zIndex={5}
                    />
                  </Label>
                  <div className={styles.arrow}>
                    <SvgArrowRight height={16} width={16} />
                  </div>
                  <Label className={styles.label}>
                    {t('label.zoneTo')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneTo0}
                      setSelected={handleZoneTo0Change}
                      zIndex={5}
                    />
                  </Label>
                </div>
                <div className={styles.row}>
                  <Label className={styles.label}>
                    {t('label.zoneFrom')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneFrom1}
                      setSelected={handleZoneFrom1Change}
                      zIndex={4}
                    />
                  </Label>
                  <div className={styles.arrow}>
                    <SvgArrowRight height={16} width={16} />
                  </div>
                  <Label className={styles.label}>
                    {t('label.zoneTo')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneTo1}
                      setSelected={handleZoneTo1Change}
                      zIndex={4}
                    />
                  </Label>
                </div>
                <div className={styles.row}>
                  <Label className={styles.label}>
                    {t('label.zoneFrom')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneFrom2}
                      setSelected={handleZoneFrom2Change}
                      zIndex={3}
                    />
                  </Label>
                  <div className={styles.arrow}>
                    <SvgArrowRight height={16} width={16} />
                  </div>
                  <Label className={styles.label}>
                    {t('label.zoneTo')}
                    <Select
                      dashed={false}
                      disabled={!selectedShape}
                      options={zones.map((z) => ({
                        label: z.name,
                        value: z.id.toString(),
                      }))}
                      placeholder={t('placeholder.zone')}
                      selected={zoneTo2}
                      setSelected={handleZoneTo2Change}
                      zIndex={3}
                    />
                  </Label>
                </div>
              </>
            )}
            <div className={styles.row}>
              <Label className={styles.label}>
                {t('label.markingName')}
                <Input
                  blackDisabled
                  dashed={false}
                  disabled={!selectedShape}
                  maxLength={15}
                  name="marking-name"
                  onBlur={handleMarkNameBlur}
                  onChange={handleMarkNameChange}
                  placeholder={t('placeholder.markingName')}
                  type="text"
                  value={markingName}
                />
              </Label>
              <div className={styles.arrow} />
              {/* <Label className={styles.label}>
                {t('label.category')}
                <Select
                  dashed={false}
                  disabled={!selectedShape}
                  options={categories.map((c) => ({
                    label: `${c.name} (${t2(`lineTypes.${c.type}`)})`,
                    value: c.id.toString(),
                  }))}
                  placeholder={t('placeholder.category')}
                  selected={category}
                  setSelected={handleCategoryChange}
                  zIndex={1}
                />
              </Label> */}
              <Label className={styles.label}>
                {t('label.type')}
                <Select
                  dashed={false}
                  disabled={!selectedShape}
                  options={Object.entries(lineTypes).map(([key, value]) => ({
                    label: `${t2(`lineTypes.${value}`)}`,
                    value: key.toString(),
                  }))}
                  placeholder={t('placeholder.type')}
                  selected={lineType}
                  setSelected={handleLineTypeChange}
                  zIndex={1}
                />
              </Label>
            </div>
            <div className={clsx([styles.row, styles.aligned])}>
              <Label className={styles.label}>
                {t('label.color')}
                <ColorSelect
                  colors={[
                    '#78BA5D',
                    '#EED647',
                    '#F3A240',
                    '#DB644F',
                    '#B87EDA',
                    '#3279BA',
                    '#55C0DC',
                    '#7FE49F',
                    '#EE82C8',
                    '#374661',
                  ]}
                  selected={color}
                  setSelected={handleColorChange}
                />
              </Label>
              <Label className={styles.label}>
                {t('label.information')}
                <div className={styles.infoWrapper}>
                  {/* <div className={styles.info}>
                <Trans
                  t={t}
                  i18nKey="information.visitors"
                  components={{ bold: <b /> }}
                  values={{ value: info.totalVisitors || '-' }}
                />
              </div> */}
                  {/* <div className={styles.info}>
                <Trans
                  t={t}
                  i18nKey="information.accuracy"
                  components={{ bold: <b /> }}
                  values={{ value: info.accuracyIndex || '-' }}
                />
              </div> */}
                  <div className={styles.info}>
                    <Trans
                      components={{ bold: <b /> }}
                      i18nKey="information.creation"
                      t={t}
                      values={{
                        creator: info?.createdBy?.email || '-',
                        date: info.createdAt
                          ? dayjs(info.createdAt).format('YYYY.MM.DD')
                          : '-',
                      }}
                    />
                  </div>
                  <div className={styles.info}>
                    <Trans
                      components={{ bold: <b /> }}
                      i18nKey="information.id"
                      t={t}
                      values={{ value: selectedShape || '-' }}
                    />
                    {selectedShape && (
                      <Tippy
                        content={
                          <div className={styles.copyTooltip}>
                            {t('information.copied')}
                          </div>
                        }
                        delay={[0, 0]}
                        onClickOutside={() => setVisible(false)}
                        placement="right"
                        theme="light-border"
                        visible={visible}
                      >
                        <SvgCopy
                          height={18}
                          onClick={handleCopyId}
                          width={18}
                        />
                      </Tippy>
                    )}
                  </div>
                </div>
              </Label>
            </div>
          </>
        )}
        <FoldoutLine />
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.organization')}
            <Input
              blackDisabled
              dashed={false}
              disabled
              name="organization"
              type="text"
              value={camera.location?.organization?.short_name}
            />
          </Label>
          <Label className={styles.label}>
            {t('label.location')}
            <Input
              blackDisabled
              dashed={false}
              disabled
              name="location"
              type="text"
              value={camera.location?.name}
            />
          </Label>
        </div>
        <FoldoutLine className={styles.bottomLine} />
        {/* <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.network')}
            <Input
              blackDisabled
              dashed={false}
              disabled
              name="network"
              type="text"
              value={t('networkStatus', {
                context: camera.network_status.toString(),
              })}
            />
          </Label>
          <Label className={styles.label}>
            {t('label.health')}
            <Input
              blackDisabled
              dashed={false}
              disabled
              name="health"
              type="text"
              value={t('healthCheck', {
                context: camera.health_check.toString(),
              })}
            />
          </Label>
        </div> */}
        {/* <div className={styles.row}>
          <Changelog />
        </div> */}
        <div className={styles.row}>
          <FoldoutAction
            icon={<SvgTrashLight height={16} width={16} />}
            onClick={handleDeleteClick}
            text={t('delete')}
          />
        </div>
      </Main>
      <Footer>
        <ButtonGroup align="right">
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            disabled={marks.length >= 32}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default React.memo(EditCamera);
